<template>
  <v-data-table
    :items="filteredItems"
    :options.sync="options"
    :filters="$static.filters"
    :loading="fetchInProgress.credentials"
    :no-data-text="$t('noDataAvailable')"
    :headers="$static.headers"
    hide-default-footer
    class="credentials-table elevation-2 flex-grow-0">
    <template #top>
      <v-row
        align="center"
        class="table-header pa-4"
        no-gutters>
        <transition
          name="slide-fade-left"
          mode="out-in">
          <v-col
            v-if="searchActive"
            key="search"
            class="table-header__searchbar pa-0">
            <v-text-field
              ref="headerSearchbar"
              v-model.trim="searchTerm"
              autofocus
              :placeholder="searchPlaceholder"
              append-icon="search"
              clearable
              class="search-field"
              @click:clear="$toggle.searchActive(true)"
              @blur="$toggle.searchActive(false)"/>
          </v-col>
        </transition>

        <transition name="slide-fade-right">
          <v-row
            v-if="!searchActive"
            key="filters"
            no-gutters
            class="table-header__filters">
            <v-icon
              color="primary"
              data-cy="search-button"
              class="mr-6"
              @click="$toggle.searchActive">
              search
            </v-icon>
            <CyDataTableFilters
              v-if="!_.$isEmpty($static.filters)"
              class="mx-2"
              :filters="$static.filters">
              <template #item-content="{ item }">
                <template v-if="item.key === 'cloudProvider'">
                  <CyIconCredential
                    size="24"
                    class="mr-4"
                    :type="item.type"/>
                  {{ item.value }}
                </template>
              </template>
            </CyDataTableFilters>
            <CyButton
              v-has-rights-to="'CreateCredential'"
              class="add-account-btn align-self-center"
              data-cy="link-add-new-credentials"
              icon="add"
              :to="{ name: 'cloudCostManagementAccountsNew' }">
              {{ $t('addNewCredential') }}
            </CyButton>
          </v-row>
        </transition>
      </v-row>
    </template>
    <template #item="{ item }">
      <tr
        :data-cy="!item.in_use ? 'link-existing-credentials' : ''"
        :class="['row', { 'row--is-disabled': item.in_use }]"
        @click.stop="gotoEditCredential(item)">
        <td class="d-flex align-center">
          <CyIconCredential
            size="24"
            class="mr-4"
            :type="item.type"/>
          {{ _.$get(getProviderExtraInfoByCredType, item.type, {}).displayNameFull }}
        </td>
        <td>{{ item.account_id || '--' }}</td>
        <td>{{ item.name }}</td>
        <td class="text-right">
          <CyTooltip
            eager
            left
            transition="slide-x-transition"
            :disabled="!item.in_use">
            <template #activator="{ on }">
              <span v-on="on">
                <CyTag
                  v-if="item.in_use"
                  small
                  variant="secondary">
                  {{ $t('inUse') }}
                </CyTag>
              </span>
            </template>
            {{ $t('credentialInUse') }}
          </CyTooltip>
        </td>
      </tr>
    </template>
    <template #footer>
      <CyDataTablePagination
        v-if="filteredItems.length"
        class="v-data-footer px-4 py-1"
        :items-length="filteredItems.length"
        :options.sync="options"
        :items-per-page-options="itemsPerPageOptions"/>
    </template>
  </v-data-table>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { pagination as createAPIPage } from '@/utils/api'
import CyDataTableFilters from '@/components/data-table/filters.vue'
import CyDataTablePagination from '@/components/data-table/pagination.vue'
import { credentialTypesAllowed, hasCloudCostManagementEB } from '@/utils/helpers/cloud-cost-management'

export default {
  name: 'CyCloudCostManagementCredentialsTable',
  components: {
    CyDataTableFilters,
    CyDataTablePagination,
  },
  data: () => ({
    cloudProviders: [],
    searchActive: false,
    searchTerm: '',
    itemsPerPageOptions: [10, 25, 50, 100],
  }),
  computed: {
    $static () {
      return {
        credentialTypesAllowed,
        filters: [
          {
            type: 'select',
            label: this.$t('cloudProvider'),
            queryParams: ['cloudProvider'],
            items: this.cloudProviders,
          },
        ],
        headers: [
          {
            text: this.$t('cloudProvider'),
            align: 'left',
            width: '215px',
            value: 'type',
          },
          {
            text: this.$t('accountId'),
            align: 'left',
            width: '144px',
            value: 'account_id',
          },
          {
            text: this.$t('cloudCostManagement.credentialName'),
            align: 'left',
            value: 'name',
          },
          {
            text: this.$t('forms.status'),
            align: 'right',
            value: 'in_use',
          },
        ],
        searchableFields: [
          {
            name: 'account_id',
            label: this.$t('accountId'),
          },
          {
            name: 'name',
            label: this.$t('cloudCostManagement.credentialName'),
          },
          {
            name: 'type',
            label: this.$t('cloudProvider'),
          },
        ],
      }
    },
    ...mapState('organization', {
      credentials: (state) => state.available.credentials,
      fetchInProgress: (state) => state.fetchInProgress,
    }),
    ...mapState('organization/cloudCostManagement', {
      accounts: (state) => state.accounts,
    }),
    ...mapGetters('layout', [
      'getDataTableProps',
      'getDataTableFilters',
    ]),
    ...mapGetters('organization/cloudCostManagement', [
      'getProviderExtraInfoByCredType',
    ]),
    options: {
      get () {
        return this.getDataTableProps(this.$route.name)
      },
      set (props) {
        this.SET_DATA_TABLE_PROPS({ name: this.$route.name, props })
      },
    },
    ccmCredentials () {
      return _.chain(this.credentials)
        .cloneDeep()
        .filter(({ type }) => _.includes(this.$static.credentialTypesAllowed, type))
        .map((credential) => {
          // credential canonical may be set in either `credential` or `external_backend.credential_canonical`,
          // depending if account is linked or master
          const cloudCostManagementAccount = _.find(this.accounts, ({ external_backend: eb, credential: cred }) => {
            return eb?.credential_canonical === credential.canonical || cred?.canonical === credential.canonical
          })
          return {
            ...credential,
            account_id: cloudCostManagementAccount?.account_id,
            in_use: !_.isEmpty(cloudCostManagementAccount?.credential) || hasCloudCostManagementEB(credential.in_use?.external_backends),
          }
        })
        .value()
    },
    filteredItems () {
      return this.filterBySearch(this.filterByFilters(this.ccmCredentials)) || []
    },
    searchPlaceholder () {
      const searchBy = this.$t('forms.searchBy')
      const labels = _.map(this.$static.searchableFields, 'label')
      const conjunction = this.$t('or')
      const listOfLabels = _.$getListFromArray(labels, { conjunction })
      return `${searchBy} ${listOfLabels}`
    },
  },
  async mounted () {
    await this.FETCH_AVAILABLE({ keyPath: 'credentials', extraParams: [null, createAPIPage(1, 100)] })
    this.setCloudProviders()
  },
  methods: {
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
    ...mapMutations('layout', [
      'SET_DATA_TABLE_PROPS',
    ]),
    filterByFilters (items) {
      const { 'cloudProvider[in]': cloudProvidersToFilter = '' } = this.getDataTableFilters(this.$route.name)

      return items.filter(({ type }) => _.$isEmpty(cloudProvidersToFilter) ||
        _.includes(cloudProvidersToFilter, _.$get(this.getProviderExtraInfoByCredType, type, {}).displayNameFull),
      )
    },
    filterBySearch (items) {
      return items.filter((item) => {
        for (const { name: property } of this.$static.searchableFields) {
          const fieldsToSearch = _.join([
            _.get(item, property, ''),
            `${this.getProviderExtraInfoByCredType[item.type]?.displayName} ${this.getProviderExtraInfoByCredType[item.type]?.displayNameFull}`,
          ], ' ')

          if (fieldsToSearch.toLowerCase().includes((this.searchTerm || '').toLowerCase())) return true
        }

        return false
      })
    },
    gotoEditCredential (credential) {
      if (!credential.in_use) this.$router.push({ name: 'cloudCostManagementAccountsNew', params: { credential } })
    },
    setCloudProviders () {
      this.cloudProviders = _.chain(this.ccmCredentials)
        .map(({ type }) => {
          const cloudProviderFullName = _.$get(this.getProviderExtraInfoByCredType, type, {}).displayNameFull
          const cloudProviderShortName = _.$get(this.getProviderExtraInfoByCredType, type, {}).displayName
          return {
            key: 'cloudProvider',
            type,
            title: `${type} ${cloudProviderFullName} ${cloudProviderShortName}`,
            value: cloudProviderFullName,
          }
        })
        .uniqBy('value')
        .value()
    },
  },
  i18n: {
    messages: {
      en: {
        accountId: 'Account ID',
        addNewCredential: 'Add new credential',
        cloudProvider: 'Cloud provider',
        credentialInUse: `This credential is already used in another Cloud Cost Management account and can't be reused`,
        credentialName: '@:cloudCostManagement.credentialName',
        inUse: 'in use',
        noDataAvailable: 'No data available',
      },
      es: {
        accountId: 'ID de la cuenta',
        addNewCredential: 'Añadir nueva credencial',
        cloudProvider: 'Proveedor de nube',
        credentialInUse: `Esta credencial ya se está utilizando en otra cuenta de Cloud Cost Management y no se puede reutilizar`,
        credentialName: '@:cloudCostManagement.credentialName',
        inUse: 'en uso',
        noDataAvailable: 'Datos no disponibles',
      },
      fr: {
        accountId: 'Identifiant de compte',
        addNewCredential: 'Ajouter un nouvel identifiant',
        cloudProvider: 'Fournisseur de cloud',
        credentialInUse: `Cet identifiant est déjà utilisé par un autre compte Cloud Cost Management et ne peut pas être réutilisé`,
        credentialName: '@:cloudCostManagement.credentialName',
        inUse: `En cours d'utilisation`,
        noDataAvailable: 'Pas de données disponibles',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.credentials-table {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  ::v-deep {
    .v-data-table__wrapper {
      border-bottom: solid 1px get-color("grey", "light-1");

      .row {
        display: table-row;
        cursor: pointer;

        &--is-disabled {
          color: get-color("grey", "dark-1");
          cursor: default;
        }
      }
    }

    .v-data-footer {
      margin-top: auto;
    }

    .icon-error {
      width: 12px;
      height: 11px;
      margin-right: 6px;
      margin-left: 2px;
    }
  }

  .table-header {
    flex-grow: 0;
    border-bottom: solid 1px get-color("grey", "light-1");

    &__filters {
      .add-account-btn {
        margin: -8px 0 -8px auto;
      }
    }

    &__searchbar {
      margin-top: -4px;
      margin-bottom: -4px;

      ::v-deep {
        .v-input__slot {
          margin-bottom: 0;
        }

        .v-text-field {
          margin-top: 0 !important;

          &__details {
            display: none;
          }
        }

        .search-field {
          padding-top: 0;
        }
      }
    }
  }

  .slide-fade-left {
    &-enter-active {
      transform-origin: left center;
      transition: all 0.6s ease-in;
      animation: slide-in 0.6s;
    }

    &-enter {
      transform: translateX(-20px) !important;
      opacity: 0;
    }

    &-leave,
    &-leave-active {
      transition: none;
    }
  }

  .slide-fade-right {
    &-enter-active {
      transform-origin: right center;
      transition: all 0.45s ease;
    }

    &-enter {
      transform: translateX(20px) !important;
      opacity: 0;
    }

    &-leave,
    &-leave-active {
      transition: none;
    }
  }
}
</style>
